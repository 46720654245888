import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedComponentsControlsModule } from '@bp/shared/components/controls';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesSvgPaymentCardBrandsIconsModule } from '@bp/shared/features/svg-payment-card-brands-icons';

import { RootComponent } from './components';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,

		SharedFeaturesSvgIconsModule,
		SharedFeaturesSvgPaymentCardBrandsIconsModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
	],
	declarations: [
		RootComponent,
		FooterComponent,
	],
	exports: [ RootComponent ],
})
export class CoreModule { }
