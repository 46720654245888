import { map, takeWhile, timer } from 'rxjs';
import m from 'moment';

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { JwtPayload, JwtToken } from '@bp/shared/utilities';
import { MockedBackendState } from '@bp/shared/services';

const SECONDS_TO_EXPIRE = 1800;

@Component({
	selector: 'bp-continue-workflow-mock-link',
	templateUrl: './continue-workflow-mock-link.component.html',
	styleUrls: [ './continue-workflow-mock-link.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		class: 'ignore-route-animation',
	},
})
export class ContinueWorkflowMockLinkComponent {

	@Input() nextWorkflowRoute!: string;

	@Input()
	set jwtPayload(value: JwtPayload | null) {
		this.jwt = value && this._buildJwtToken(value);
	}

	jwt!: string | null;

	isMock = MockedBackendState.isActive;

	secondsLeftBeforeExpire$ = 	timer(0, 1000)
		.pipe(
			map(passedSeconds => SECONDS_TO_EXPIRE - passedSeconds),
			takeWhile(leftSeconds => leftSeconds >= 0),
		);

	private _buildJwtToken(jwtPayload: JwtPayload): string {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		return JwtToken.encode({
			...jwtPayload,
			exp: m().add(SECONDS_TO_EXPIRE, 'second')
				.unix(),
		});
	}

}
