import type { Moment } from 'moment';

import { createAction, props } from '@ngrx/store';

import { TELEMETRY_HIDDEN_MARK } from '@bp/shared/models/core';

import { ILoginQueryParams } from '@bp/shared-domains-identity';

import { WizardSession, WizardStep } from '../models';

export const SCOPE = '[No Touch Wizard][Domain][WizardSession]';

export const wizardSessionEffectsInit = createAction(
	`${ SCOPE } Effects Init`,
);

export const createWizardSession = createAction(
	`${ SCOPE } Create Wizard Session`,
	props<{ wizardConfigId?: string }>(),
);

export const loadWizardSession = createAction(
	`${ SCOPE } Load Wizard Session`,
);

export const setWizardSession = createAction(
	`${ SCOPE } Set Wizard Session`,
	props<{ wizardSession: WizardSession }>(),
);

export const updateLoginUrlWithLoginQueryParams = createAction(
	`${ SCOPE } Update Login Url With Login Query Params ${ TELEMETRY_HIDDEN_MARK }`,
	props<{ loginQueryParams: ILoginQueryParams }>(),
);

export const saveStep = createAction(
	`${ SCOPE } Save Step`,
	props<{ step: WizardStep }>(),
);

export const jwtFromUrl = createAction(
	`${ SCOPE } JWT From Url ${ TELEMETRY_HIDDEN_MARK }`,
	props<{ jwt: string }>(),
);

export const startWizardSessionExpiryTimer = createAction(
	`${ SCOPE } Start Wizard Session Expiry Timer`,
	props<{ expiresAt: Moment }>(),
);

export const wizardSessionExpired = createAction(
	`${ SCOPE } Wizard Session Expired`,
);

export const refreshWizardSessionToken = createAction(
	`${ SCOPE } Refresh Token`,
);

export const resetWizardSession = createAction(
	`${ SCOPE } Reset`,
);
