import { combineLatest, defer, EMPTY, of, tap, timer } from 'rxjs';
import { distinctUntilChanged, exhaustMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Actions, concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { apiResult, apiVoidResult } from '@bp/shared/models/common';
import { HttpConfigService, RouterService, StorageService } from '@bp/shared/services';
import { UrlHelper } from '@bp/shared/utilities';
import { takeFirstPresent } from '@bp/shared/rxjs';
import { GoogleTagService } from '@bp/shared/features/analytics';

import { NotFoundPageComponent, GeneralErrorPageComponent } from '@bp/admins-shared/pages/errors';

import { NoTouchWizardRoutePathSegments } from '@bp/no-touch-wizard/shared/models';

import { WizardSessionApiService } from '../services';
import { WizardFeature, WizardSession, WizardStep } from '../models';

import { WizardSessionFacade } from './wizard-session.facade';
import {
	createWizardSession, jwtFromUrl, loadWizardSession, refreshWizardSessionToken, saveStep, wizardSessionEffectsInit, wizardSessionExpired, startWizardSessionExpiryTimer, resetWizardSession
} from './wizard-session.actions';
import {
	createWizardSessionFailure, createWizardSessionSuccess, loadWizardSessionFailure, loadWizardSessionSuccess, refreshTokenFailure, refreshTokenSuccess, saveStepFailure, saveStepSuccess, setWizardSessionFromUrlJwtFailure, setWizardSessionFromUrlJwtSuccess
} from './wizard-session-api.actions';
import { WIZARD_SESSION_PATH_IN_STATE } from './wizard-session.reducer';

@Injectable()
export class WizardSessionEffects implements OnInitEffects {

	jwtFromUrl$ = createEffect(() => this._actions$.pipe(
		ofType(wizardSessionEffectsInit),
		mergeMap(() => {
			const jwt = new URL(window.location.toString())
				.searchParams.get('jwt');

			return jwt
				? of(jwtFromUrl({ jwt }))
				: EMPTY;
		}),
	));

	setWizardSessionFromUrlJWT$ = createEffect(() => this._actions$.pipe(
		ofType(jwtFromUrl),
		mergeMap(({ jwt }) => defer(() => of(new WizardSession(jwt))).pipe(
			apiResult(setWizardSessionFromUrlJwtSuccess, setWizardSessionFromUrlJwtFailure),
		)),
	));

	createWizardSession$ = createEffect(() => this._actions$.pipe(
		ofType(createWizardSession),
		exhaustMap(request => this._wizardSessionApiService
			.create(request)
			.pipe(apiResult(createWizardSessionSuccess, createWizardSessionFailure))),
	));

	loadWizardSession$ = createEffect(() => this._actions$.pipe(
		ofType(loadWizardSession),
		exhaustMap(() => this._wizardSessionApiService
			.load()
			.pipe(apiResult(loadWizardSessionSuccess, loadWizardSessionFailure))),
	));

	onWizardSessionChange = this._wizardSessionFacade.session$
		.subscribe(wizardSession => {
			this._setupGoogleTagGlobalParams(wizardSession);

			void this._httpConfig.setAuthorizationHeader(wizardSession.jwt);

			void this._storageService.setIfDifferentFromStored(wizardSession, WIZARD_SESSION_PATH_IN_STATE);

			this._navigateToWelcomePageIfWizardCompleted(wizardSession);
		});

	onResetWizardSession$ = createEffect(
		() => this._actions$.pipe(
			ofType(resetWizardSession),
			tap(() => {
				void this._httpConfig.removeAuthorizationHeader();

				void this._storageService.setIfDifferentFromStored(null, WIZARD_SESSION_PATH_IN_STATE);
			}),
		),
		{ dispatch: false },
	);

	wizardSessionExpiryTimer$ = createEffect(() => this._actions$.pipe(
		ofType(startWizardSessionExpiryTimer),
		switchMap(action => timer(action.expiresAt.toDate())
			.pipe(map(wizardSessionExpired))),
	));

	startWizardSessionExpiryTimer$ = createEffect(() => this._wizardSessionFacade.session$.pipe(
		map(wizardSession => startWizardSessionExpiryTimer({ expiresAt: wizardSession.expiresAt })),
	));

	onWizardSessionExpiredRedirectToWizardSessionExpiredPage$ = createEffect(
		() => this._actions$.pipe(
			ofType(wizardSessionExpired),
			tap(() => {
				this._wizardSessionFacade.refreshToken();

				void this._router.navigate([ NoTouchWizardRoutePathSegments.SessionExpired ]);
			}),
		),
		{ dispatch: false },
	);

	refreshToken$ = createEffect(() => this._actions$.pipe(
		ofType(refreshWizardSessionToken),
		concatLatestFrom(() => this._wizardSessionFacade.session$.pipe(
			map(wizardSession => wizardSession.userEmail),
			takeFirstPresent,
		)),
		exhaustMap(([ , email ]) => this._wizardSessionApiService
			.sendContinueWizardEmail(email)
			.pipe(
				// we need the session access token to make the api call, only after it we can reset wizard session
				tap({ subscribe: () => void this._wizardSessionFacade.reset() }),
				apiVoidResult(refreshTokenSuccess, refreshTokenFailure),
			)),
	));

	onWizardSessionFailureRedirectToErrorPage$ = createEffect(
		() => this._actions$.pipe(
			ofType(
				createWizardSessionFailure,
				loadWizardSessionFailure,
				setWizardSessionFromUrlJwtFailure,
				refreshTokenFailure,
			),
			tap(() => void this._router.navigateByUrl('error')),
		),
		{ dispatch: false },
	);

	onNavigationEndSaveCurrentStep = combineLatest([
		this._wizardSessionFacade.session$,
		this._routerService.navigationEnd$,
	])
		.pipe(
			filter(() => ![ NotFoundPageComponent, GeneralErrorPageComponent ].includes(
				<any>UrlHelper.getLastPrimaryRoute(this._activatedRoute).component,
			)),
			filter(([ wizardSession ]) => wizardSession.hasPermission(WizardFeature.updateSession)),
			map(() => window.location.pathname),
			distinctUntilChanged(),
			filter(locationPathname => this._isWizardStepLocationPathname(locationPathname)),
		)
		.subscribe(locationPathname => void this._wizardSessionFacade.saveStep(
			new WizardStep({ type: locationPathname }),
		));

	saveStep$ = createEffect(() => this._actions$.pipe(
		ofType(saveStep),
		mergeMap(({ step }) => this._wizardSessionApiService
			.saveStep(step)
			.pipe(apiVoidResult(saveStepSuccess, saveStepFailure))),
	));

	onWizardSessionLoadRedirectToLastStep$ = createEffect(
		() => this._actions$.pipe(
			ofType(loadWizardSessionSuccess),
			filter(({ result }) => !!result.lastStep),
			tap(({ result }) => void this._router.navigateByUrl(result.lastStep!.type)),
		),
		{ dispatch: false },
	);

	constructor(
		private readonly _actions$: Actions,
		private readonly _router: Router,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _routerService: RouterService,
		private readonly _wizardSessionFacade: WizardSessionFacade,
		private readonly _wizardSessionApiService: WizardSessionApiService,
		private readonly _httpConfig: HttpConfigService,
		private readonly _storageService: StorageService,
		private readonly _googleTagService: GoogleTagService,
	) {

	}

	ngrxOnInitEffects(): Action {
		return wizardSessionEffectsInit();
	}

	private _isWizardStepLocationPathname(locationPathname: string): boolean {
		if (!locationPathname.includes(NoTouchWizardRoutePathSegments.Wizard))
			return false;

		return ![ NoTouchWizardRoutePathSegments.Continue, NoTouchWizardRoutePathSegments.Welcome ]
			.some(excludePathName => locationPathname.includes(excludePathName));
	}

	private _navigateToWelcomePageIfWizardCompleted(wizardSession: WizardSession): void {
		if (!wizardSession.isCompleted)
			return;

		void this._router.navigate([
			NoTouchWizardRoutePathSegments.Wizard,
			NoTouchWizardRoutePathSegments.Welcome,
		]);
	}

	private _setupGoogleTagGlobalParams(wizardSession: WizardSession): void {
		this._googleTagService.setGlobalVariables({
			// eslint-disable-next-line @typescript-eslint/naming-convention
			user_id: wizardSession.userIdentityId,
			userEmail: wizardSession.userEmail,
			wizardSessionId: wizardSession.wizardSessionId,
			wizardSetupId: wizardSession.wizardSetupId,
			leadId: wizardSession.leadId,
			merchantId: wizardSession.merchantId,
			zohoModuleIds: {
				accountId: wizardSession.crmMerchant?.crmMerchantId,
				contactId: wizardSession.crmMerchant?.owner.id,
			},
		});
	}
}
