import { createAction, props } from '@ngrx/store';

import { ErrorActionPayload, ResultActionPayload } from '@bp/shared/models/common';

import { WizardSession } from '../models';

import { SCOPE } from './wizard-session.actions';

const API_SCOPE = `[API]${ SCOPE }`;

export const createWizardSessionSuccess = createAction(
	`${ API_SCOPE } Create Wizard Session Success`,
	props<ResultActionPayload<WizardSession>>(),
);

export const createWizardSessionFailure = createAction(
	`${ API_SCOPE } Create Wizard Session Failure`,
	props<ErrorActionPayload>(),
);

export const setWizardSessionFromUrlJwtSuccess = createAction(
	`${ API_SCOPE } Set Wizard Session From Url Jwt Success`,
	props<ResultActionPayload<WizardSession>>(),
);

export const setWizardSessionFromUrlJwtFailure = createAction(
	`${ API_SCOPE } Set Wizard Session From Url Jwt Failure`,
	props<ErrorActionPayload>(),
);

export const loadWizardSessionSuccess = createAction(
	`${ API_SCOPE } Load Wizard Session Success`,
	props<ResultActionPayload<WizardSession>>(),
);

export const loadWizardSessionFailure = createAction(
	`${ API_SCOPE } Load Wizard Session Failure`,
	props<ErrorActionPayload>(),
);

export const saveStepSuccess = createAction(
	`${ API_SCOPE } Save Step Success`,
);

export const saveStepFailure = createAction(
	`${ API_SCOPE } Save Step Failure`,
	props<ErrorActionPayload>(),
);

export const refreshTokenSuccess = createAction(
	`${ API_SCOPE } Refresh Token Success`,
);

export const refreshTokenFailure = createAction(
	`${ API_SCOPE } Refresh Token Failure`,
	props<ErrorActionPayload>(),
);
