import { firstValueFrom } from 'rxjs';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationRef, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { NavigationActionTiming, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';

import { FORM_FIELD_DEFAULT_OPTIONS, IFormFieldDefaultOptions, SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedFeaturesValidationModule } from '@bp/shared/features/validation';
import { SharedPipesModule } from '@bp/shared/pipes';
import { FirebaseAppConfig, FIREBASE_APP_CONFIG, PLATFORM, SharedServicesModule, StorageService, TelemetryService } from '@bp/shared/services';
import { environment } from '@bp/shared/environments';
import { APP_STORAGE_PREFIX } from '@bp/shared/models/core';
import { LOCAL_SERVER_META_REDUCERS_PROVIDERS, REMOTE_SERVER_META_REDUCERS_PROVIDERS, sharedReducer } from '@bp/shared/state';
import { Platform } from '@bp/shared/typings';
import { ensureType } from '@bp/shared/utilities';
import { SharedFeaturesAnalyticsModule } from '@bp/shared/features/analytics';

import { AdminsSharedPagesErrorsModule } from '@bp/admins-shared/pages/errors';
import { AdminsSharedCoreComponentsModule } from '@bp/admins-shared/core/components';
import { SUBSCRIPTION_PLANS_API_COLLECTION_PATH } from '@bp/admins-shared/domains/subscription-plans';

import { NoTouchWizardDomainsSessionRootModule } from '@bp/no-touch-wizard/domains/session';
import { NoTouchWizardSharedComponentsModule } from '@bp/no-touch-wizard/shared/components';

import { AppStartupService } from './app-startup.service';
import { CoreModule, RootComponent } from './core';
import { AppRoutingModule } from './app-routing.module';
import { HomePageComponent } from './pages/home-page/home-page.component';

TelemetryService.log('App module execution begun');

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		BrowserAnimationsModule,

		StoreModule.forRoot(sharedReducer, {
			runtimeChecks: {
				strictActionImmutability: false,
				// StrictActionSerializability: false,
				strictStateImmutability: false,

				/*
				 * StrictStateSerializability: false
				 * We dont use the built-in immutability check because
				 * it freezes the whole moment structure
				 * so we utilize custom immutabilityCheckMetaReducer
				 */
			},
		}),

		StoreRouterConnectingModule.forRoot({
			routerState: RouterState.Minimal,
			navigationActionTiming: NavigationActionTiming.PostActivation,
		}),

		EffectsModule.forRoot([]),

		SharedPipesModule.forRoot(),
		SharedComponentsCoreModule.forRoot(),
		SharedFeaturesValidationModule.forRoot(),
		SharedServicesModule.forRoot(),
		SharedFeaturesAnalyticsModule.forRoot(),

		CoreModule,
		AppRoutingModule,

		AdminsSharedCoreComponentsModule,

		NoTouchWizardSharedComponentsModule,
		NoTouchWizardDomainsSessionRootModule,

		AdminsSharedPagesErrorsModule, // Should be the last module with routes to properly catch all notfound routes
	],
	providers: [
		environment.isRemoteServer ? REMOTE_SERVER_META_REDUCERS_PROVIDERS : LOCAL_SERVER_META_REDUCERS_PROVIDERS,
		{
			provide: FORM_FIELD_DEFAULT_OPTIONS,
			useValue: <IFormFieldDefaultOptions>{
				appearance: 'round',
				compact: true,
			},
		},
		{
			provide: StorageService,
			useFactory: () => new StorageService({
				prefix: APP_STORAGE_PREFIX,
				storageType: 'sessionStorage',
			}),
		},
		{
			provide: FIREBASE_APP_CONFIG,
			useValue: <FirebaseAppConfig> {
				appId: '1:977741303368:web:30eda1c717b10c3e00927d',
			},
		},
		{
			provide: PLATFORM,
			useValue: ensureType<Platform>('wizard'),
		},
		{ provide: SUBSCRIPTION_PLANS_API_COLLECTION_PATH, useValue: 'wizard/subscription-plans' },
	],
	bootstrap: [ RootComponent ],
	declarations: [ HomePageComponent ],
})
export class AppModule {

	constructor(
		private readonly _appStartupService: AppStartupService,
		private readonly _app: ApplicationRef,
	) {
		void this._whenAppIsStableInitStartupLogic();
	}

	private async _whenAppIsStableInitStartupLogic(): Promise<void> {
		await firstValueFrom(this._app.isStable);

		this._appStartupService.init();
	}

}
