import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { buildUrl } from '@bp/shared/utilities';

import type { WizardSession } from '../models';

import {
	createWizardSessionFailure, createWizardSessionSuccess, loadWizardSessionSuccess, setWizardSessionFromUrlJwtSuccess
} from './wizard-session-api.actions';
import { createWizardSession, resetWizardSession, updateLoginUrlWithLoginQueryParams, setWizardSession } from './wizard-session.actions';

export const FEATURE_STATE_KEY = '[feature]:wizard-session';
export const WIZARD_SESSION_STATE_KEY = 'wizardSession';
export const WIZARD_SESSION_PATH_IN_STATE = `${ FEATURE_STATE_KEY }.${ WIZARD_SESSION_STATE_KEY }`;

export interface IState {
	[WIZARD_SESSION_STATE_KEY]: WizardSession | null;

	loginUrl: string | null;

	creating: boolean;
}

export const initialState: IState = {

	wizardSession: null,

	loginUrl: null,

	creating: false,

};

const wizardSessionReducer = createReducer(

	initialState,

	on(resetWizardSession, (state): IState => ({
		...state,
		wizardSession: null,
	})),

	on(
		createWizardSession,
		(state): IState => ({
			...state,
			creating: true,
		}),
	),

	on(
		loadWizardSessionSuccess,
		createWizardSessionSuccess,
		setWizardSessionFromUrlJwtSuccess,
		 (state, { result }): IState => ({
			 ...state,
			 wizardSession: result,
			 loginUrl: result.loginUrl,
		}),
	),

	on(
		setWizardSession,
		 (state, { wizardSession }): IState => ({
			...state,
			wizardSession,
			loginUrl: wizardSession.loginUrl,
		}),
	),

	on(
		updateLoginUrlWithLoginQueryParams,
		 (state, { loginQueryParams }): IState => ({
			...state,
			loginUrl: state.loginUrl
				? buildUrl(state.loginUrl, Object.entries(loginQueryParams))
				: null,
		}),
	),

	on(
		createWizardSessionSuccess,
		createWizardSessionFailure,
		(state): IState => ({
			...state,
			creating: false,
		}),
	),

);

export function reducer(state: IState | undefined, action: Action): IState {
	return wizardSessionReducer(state, action);
}
