import { CommonFeatureActions, Feature } from '@bp/shared-domains-identity';

export class WizardFeature<T extends CommonFeatureActions = CommonFeatureActions> extends Feature<T> {

	static updateSession = new WizardFeature(CommonFeatureActions);

	static createLead = new WizardFeature(CommonFeatureActions);

	static sendEmail = new WizardFeature(CommonFeatureActions);

	static confirmationEmail = new WizardFeature(CommonFeatureActions);

	static createPassword = new WizardFeature(CommonFeatureActions);

	static wizard = new WizardFeature(CommonFeatureActions);

	static getSession = new WizardFeature(CommonFeatureActions);

}
