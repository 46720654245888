import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'bp-information-page-shell',
	templateUrl: './information-page-shell.component.html',
	styleUrls: [ './information-page-shell.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationPageShellComponent {

}
