import { Alias, DTO, MapFromDTO, MetadataEntity } from '@bp/shared/models/metadata';

export type WizardSessionJwtPayloadDTO = DTO<WizardSessionJwtPayload>;

export class WizardSessionJwtPayload extends MetadataEntity {

	@MapFromDTO()
	wizardSessionId!: string;

	@MapFromDTO()
	wizardSetupId!: string;

	@MapFromDTO()
	leadId?: string;

	@Alias('id')
	merchantId?: string;

	@MapFromDTO()
	userIdentityId?: string;

	@MapFromDTO()
	permissions!: string[];

	@MapFromDTO()
	userEmail?: string;

	@MapFromDTO()
	userFullName?: string;

	@MapFromDTO()
	exp!: number;

	constructor(dto?: WizardSessionJwtPayloadDTO) {
		super(dto);
	}
}
