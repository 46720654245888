import { Default, DTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { WizardIntegration } from './wizard-integration';

export class WizardConfig extends MetadataEntity {

	@Mapper(WizardIntegration)
	@Default(null)
	defaultIntegration!: WizardIntegration | null;

	@Mapper(WizardIntegration)
	@Default(null)
	onlyIntegration!: WizardIntegration | null;

	@Mapper(WizardIntegration)
	@Default(null)
	integrations!: WizardIntegration[] | null;

	constructor(dto?: DTO<WizardConfig>) {
		super(dto);
	}

}
