export enum WizardConfigIds {

	Default = '9d716954-b052-45d3-82d2-8926cd3ad004',

	EnterpriseMerchant = 'd4ecedc9-fc46-4483-abb8-421019c272c4',

	EnterpriseOAuthExistingEmail = '412ff14a-5c3b-45c4-bb59-1eed84df35f7',

	OAuthExistingEmail = 'e9c8f8f9-f8e1-4b5f-b8e2-f9f8f9f9f9f9',

	OAuthContinueWorkflow = '97e1a88c-0272-41ff-8169-dcc336ae2427',

	Woocommerce = 'eab099ef-39d0-45de-8002-99eea718429a',

	Wordpress = '42e9e614-4201-4c46-9655-f237d2c9094f',

	Magento = '7c8c407a-ff43-4167-b2a7-ba2b76a2c97b',

}
