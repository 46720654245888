<small *ngIf="environment.isNotProduction && mockedBackendIsActive" class="mr-3">
	<bp-select-field
		panelClass="dev-workflows-select-panel"
		appearance="standard"
		label="Go To Wizard Workflow"
		[items]="devLinks"
	>
		<a *bpSelectOption="let devLink" class="reset-default-decoration" [href]="devLink.url">
			{{ devLink.title }}
		</a>
	</bp-select-field>
</small>

<bp-footer-mock-links></bp-footer-mock-links>

<small class="version">Powered by Bridgerpay <bp-app-version></bp-app-version></small>
