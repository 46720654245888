import { Enumeration } from '@bp/shared/models/core/enum';

export class WizardIntegration extends Enumeration {

	static woocommerce = new WizardIntegration({
		installationGuideUrl: 'https://storage.googleapis.com/bridger-marketing/woocommerce.pdf',
		installationGuideVideoId: '124gpQwzkeQ',
		pluginUrl: 'https://wordpress.org/plugins/bridgerpay-woocommerce/',
		colorfulLogoFileName: 'woocommerce-colorful.png',
		whiteLogoFileName: 'woocommerce-white.png',
	});

	static wordpress = new WizardIntegration({
		installationGuideUrl: 'https://storage.googleapis.com/bridger-marketing/wordpress.pdf',
		installationGuideVideoId: 'agyoCtm0YA0',
		pluginUrl: 'https://wordpress.org/plugins/bridgerpay-woocommerce/',
		colorfulLogoFileName: 'wordpress-colorful.png',
		whiteLogoFileName: 'wordpress-white.png',
	});

	static magento = new WizardIntegration({
		installationGuideUrl: 'https://storage.googleapis.com/bridger-marketing/magento.pdf',
		installationGuideVideoId: 'BGUnIIaK-Q8',
		pluginUrl: 'https://marketplace.magento.com/bridgerpay-module-payments.html',
		description: 'BridgerPay supports Magento 1 and 2',
		colorfulLogoFileName: 'magento-colorful.png',
		whiteLogoFileName: 'magento-white.png',
	});

	static prestashop = new WizardIntegration({
		installationGuideUrl: 'https://storage.googleapis.com/bridger-marketing/BridgerPayforPrestaShop2.pdf',
		installationGuideVideoId: 'GWEG_u6syos',
		pluginUrl: 'https://addons.prestashop.com/en/payment-card-wallet/88248-bridgerpay.html',
		colorfulLogoFileName: 'prestashop-colorful.png',
	});

	readonly installationGuideUrl: string;

	readonly installationGuideVideoId: string;

	readonly pluginUrl: string;

	readonly description?: string;

	readonly colorfulLogoUrl?: string;

	readonly whiteLogoUrl?: string;

	constructor(options: {
		installationGuideUrl: string;
		installationGuideVideoId: string;
		pluginUrl: string;
		colorfulLogoFileName: string;
		whiteLogoFileName?: string;
		description?: string;
		displayName?: string;
	}) {
		super(options.displayName);

		this.installationGuideUrl = options.installationGuideUrl;

		this.installationGuideVideoId = options.installationGuideVideoId;

		this.pluginUrl = options.pluginUrl;

		this.description = options.description;

		const baseLogoUrl = '/assets/integrations';

		if (options.colorfulLogoFileName)
			this.colorfulLogoUrl = `${ baseLogoUrl }/${ options.colorfulLogoFileName }`;

		if (options.whiteLogoFileName)
			this.whiteLogoUrl = `${ baseLogoUrl }/${ options.whiteLogoFileName }`;
	}

}
