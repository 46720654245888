import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ROUTE_HIT_ANIMATIONS } from '@bp/shared/animations';
import { MediaService } from '@bp/shared/features/media';
import { EnvironmentService } from '@bp/shared/services';

@Component({
	selector: 'bp-root',
	templateUrl: './root.component.html',
	styleUrls: [ './root.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ ROUTE_HIT_ANIMATIONS ],
	host: {
		class: 'bp-scrollbar',
	},
})
export class RootComponent {

	shouldAnimateOutlet = false;

	constructor(
		public environment: EnvironmentService,
		public readonly mediaService: MediaService,
	) {
	}

}
