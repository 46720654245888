import { distinctUntilChanged } from 'rxjs';

import { Injectable } from '@angular/core';

import { IntercomService, SharedStartupService, TelemetryService } from '@bp/shared/services';
import { AnalyticsService, UtmService } from '@bp/shared/features/analytics';

import { WizardSessionFacade } from '@bp/no-touch-wizard/domains/session';

@Injectable({
	providedIn: 'root',
})
export class AppStartupService {

	constructor(
		private readonly _sharedStartupService: SharedStartupService,
		private readonly _utmService: UtmService,
		private readonly _telemetry: TelemetryService,
		private readonly _intercomService: IntercomService,
		private readonly _wizardSessionFacade: WizardSessionFacade,
		private readonly _analyticsService: AnalyticsService,
	) {

	}

	init(): void {
		this._sharedStartupService.init();

		this._utmService.init();

		this._analyticsService.init();

		this._intercomService.init();

		this._identifyUserForThirdPartyServices();

		this._tryLockInPortraitOrientation();
	}

	private _identifyUserForThirdPartyServices(): void {
		this._wizardSessionFacade.session$
			.pipe(distinctUntilChanged((a, b) => a.userEmail === b.userEmail))
			.subscribe(session => {
				this._telemetry.identifyUser(session.userEmail!, {
					name: session.userFullName,
					merchantId: session.merchantId,
				});

				void this._intercomService.update({
					name: session.userFullName,
					email: session.userEmail,
				});
			});
	}

	private _tryLockInPortraitOrientation(): void {
		try {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			window.screen.orientation.lock('portrait').catch(() => { });
		} catch {

		}
	}

}
