import type { Moment } from 'moment';
import moment from 'moment';
import { isArray, isNumber, isString } from 'lodash-es';

import { DTO, Mapper, MapFromDTO, MetadataEntity, Default, Secret } from '@bp/shared/models/metadata';
import type { PickBy } from '@bp/shared/typings';
import { JwtToken } from '@bp/shared/utilities';

import { FeatureAction, featurePermissionsMapFactory, FeaturePermission } from '@bp/shared-domains-identity';

import { WizardFeature } from './permissions';
import { WizardConfig } from './wizard-config';
import { WizardStep } from './wizard-step';
import { WizardCrmMerchant } from './enterprise/wizard-crm-merchant';
import { WizardSessionJwtPayload } from './wizard-session-jwt-payload';

export class WizardSession extends MetadataEntity {
	private static _convertToDto(dtoOrJWT: DTO<WizardSession & { token: string }> | string): DTO<WizardSession> {
		return isString(dtoOrJWT)
			? { jwt: dtoOrJWT }
			: {
				...dtoOrJWT,
				jwt: dtoOrJWT.token ?? dtoOrJWT.jwt,
			  };
	}

	@Secret()
	readonly jwt!: string;

	@MapFromDTO()
	readonly loginUrl!: string;

	@Secret()
	readonly cashierLicenseKey!: string;

	@Mapper(WizardConfig)
	@Default(new WizardConfig())
	readonly config!: WizardConfig;

	@Mapper(WizardStep)
	readonly lastStep?: WizardStep;

	@Mapper(WizardStep)
	readonly history?: WizardStep[];

	@MapFromDTO()
	readonly isCompleted?: boolean;

	@Mapper(WizardCrmMerchant)
	@Default(null)
	readonly crmMerchant!: WizardCrmMerchant | null;

	get userEmail(): string | undefined {
		return this.jwtPayload.userEmail;
	}

	get userFullName(): string | undefined {
		return this.jwtPayload.userFullName;
	}

	get wizardSessionId(): string {
		return this.jwtPayload.wizardSessionId;
	}

	get wizardSetupId(): string {
		return this.jwtPayload.wizardSetupId;
	}

	get leadId(): string | undefined {
		return this.jwtPayload.leadId;
	}

	get userIdentityId(): string | undefined {
		return this.jwtPayload.userIdentityId;
	}

	get merchantId(): string | undefined {
		return this.jwtPayload.merchantId;
	}

	get isEnterprise(): boolean {
		return !!this.crmMerchant;
	}

	readonly expiresAt!: Moment;

	readonly featurePermissions = new Map<FeatureAction | WizardFeature, FeaturePermission<WizardFeature>>();

	readonly jwtPayload: WizardSessionJwtPayload;

	constructor(dtoOrJWT: Parameters<typeof WizardSession._convertToDto>[0]) {
		super(WizardSession._convertToDto(dtoOrJWT));

		this.jwtPayload = this._decodeJWT();

		this.expiresAt = moment.unix(this.jwtPayload.exp);

		this.featurePermissions = this._parseJWTPermissions();
	}

	hasPermission(featureOrAction: FeatureAction | WizardFeature): boolean {
		return this.featurePermissions.has(featureOrAction);
	}

	private _decodeJWT(): WizardSessionJwtPayload {
		const decoded = JwtToken.decode<DTO<WizardSessionJwtPayload>>(this.jwt);
		const permissions = decoded.permissions ?? [];

		if (!isNumber(decoded.exp))
			throw new Error('Session JWT must contain expiration timestamp');

		return new WizardSessionJwtPayload({
			...decoded,
			permissions: isArray(permissions) ? permissions : [ permissions ],
		});
	}

	protected _parseJWTPermissions(): PickBy<WizardSession, 'featurePermissions'> {
		return featurePermissionsMapFactory(this.jwtPayload.permissions, featureName => WizardFeature.parseStrict(featureName));
	}
}
