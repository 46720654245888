import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StorageService } from '@bp/shared/services';

import { WizardSessionFacade } from '@bp/no-touch-wizard/domains/session';
import { LEAD_OWNER_EMAIL, NoTouchWizardRoutePathSegments } from '@bp/no-touch-wizard/shared/models';

@Component({
	templateUrl: './home-page.component.html',
	styleUrls: [ './home-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent {

	constructor(
		private readonly _route: ActivatedRoute,
		private readonly _router: Router,
		private readonly _wizardSessionFacade: WizardSessionFacade,
		private readonly _storageService: StorageService,
	) {
		this._createSessionAndNavigateToCreateLeadPage();
	}

	private _createSessionAndNavigateToCreateLeadPage(): void {
		const { id, configId, ownerEmail } = this._route.snapshot.queryParams;

		this._wizardSessionFacade.createWizardSession(id || configId);

		if (ownerEmail)
			this._storageService.set(LEAD_OWNER_EMAIL, ownerEmail);

		void this._router.navigate([ NoTouchWizardRoutePathSegments.Lead ]);
	}

}
