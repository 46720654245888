import { map } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { filterPresent, OptionalBehaviorSubject } from '@bp/shared/rxjs';

import { ILoginQueryParams } from '@bp/shared-domains-identity';

import { WizardSession, WizardStep } from '../models';

import { selectLoginUrl, selectCreating, selectWizardSession } from './wizard-session.selectors';
import {
	createWizardSession,
	loadWizardSession,
	refreshWizardSessionToken,
	resetWizardSession,
	saveStep,
	updateLoginUrlWithLoginQueryParams,
	setWizardSession
} from './wizard-session.actions';
import { createWizardSessionSuccess } from './wizard-session-api.actions';

@Injectable({ providedIn: 'root' })
export class WizardSessionFacade {
	creating$ = this._store$.select(selectCreating);

	session$ = this._store$.select(selectWizardSession).pipe(filterPresent);

	// eslint-disable-next-line rxjs/no-exposed-subjects
	mockSession$ = new OptionalBehaviorSubject<WizardSession>();

	session: WizardSession | null = null;

	wizardConfig$ = this.session$.pipe(map(session => session.config));

	createSessionSuccess$ = this._actions$.pipe(
		ofType(createWizardSessionSuccess),
		map(action => action.result),
	);

	loginUrl$ = this._store$.select(selectLoginUrl);

	loginUrl: string | null = null;

	constructor(protected readonly _store$: Store, protected readonly _actions$: Actions) {
		this._keepWizardSessionPropertyUpdated();

		this._keepLoginUrlUpdated();

		this._keepMockSessionSubjectUpdated();
	}

	reset(): void {
		this._store$.dispatch(resetWizardSession());
	}

	refreshToken(): void {
		if (this.session?.jwt)
			this._store$.dispatch(refreshWizardSessionToken());
	}

	createWizardSession(wizardConfigId?: string): void {
		this._store$.dispatch(createWizardSession({ wizardConfigId }));
	}

	setWizardSession(wizardSession: WizardSession): void {
		this._store$.dispatch(setWizardSession({ wizardSession }));
	}

	loadWizardSession(): void {
		this._store$.dispatch(loadWizardSession());
	}

	saveStep(step: WizardStep): void {
		this._store$.dispatch(saveStep({ step }));
	}

	updateLoginUrlWithLoginQueryParams(loginQueryParams: ILoginQueryParams): void {
		this._store$.dispatch(updateLoginUrlWithLoginQueryParams({ loginQueryParams }));
	}

	private _keepWizardSessionPropertyUpdated(): void {
		this._store$
			.select(selectWizardSession)
			// eslint-disable-next-line ngrx/no-store-subscription
			.subscribe(session => (this.session = session));
	}

	private _keepLoginUrlUpdated(): void {
		this.loginUrl$.subscribe(loginUrl => (this.loginUrl = loginUrl));
	}

	private _keepMockSessionSubjectUpdated(): void {
		this.session$.subscribe(this.mockSession$);
	}
}
