import { ROOT_EFFECTS_INIT } from '@ngrx/effects';
import type { ActionReducer } from '@ngrx/store';

import { WizardSession } from '../models';

import { IState, WIZARD_SESSION_STATE_KEY } from './wizard-session.reducer';

export function wizardSessionStateRehydratorMetaReducer(reducer: ActionReducer<IState>): ActionReducer<IState> {
	return function(state, action) {
		const newState = reducer(state, action);

		if (action.type !== ROOT_EFFECTS_INIT)
			return newState;

		const storedWizardSessionState = newState[WIZARD_SESSION_STATE_KEY];

		return storedWizardSessionState
			? {
				...newState,
				[WIZARD_SESSION_STATE_KEY]: new WizardSession(
					storedWizardSessionState,
				),
			}
			: newState;
	};
}
