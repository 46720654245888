import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { IState } from './wizard-session.reducer';
import { FEATURE_STATE_KEY } from './wizard-session.reducer';

export const selectFeatureState = createFeatureSelector<IState>(FEATURE_STATE_KEY);

export const selectWizardSession = createSelector(
	selectFeatureState,
	state => state.wizardSession,
);

export const selectLoginUrl = createSelector(
	selectFeatureState,
	state => state.loginUrl,
);

export const selectCreating = createSelector(
	selectFeatureState,
	state => state.creating,
);
