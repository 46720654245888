<div class="information-page-shell-content main-page-content">
	<div class="scene">
		<ng-content select=".ng-content-scene"></ng-content>
	</div>

	<h2 class="title uppercase">
		<ng-content select=".ng-content-title"></ng-content>
	</h2>

	<h3 class="subtitle">
		<ng-content select=".ng-content-subtitle"></ng-content>
	</h3>

	<div class="action">
		<ng-content select=".ng-content-action"></ng-content>
	</div>

	<div class="ng-content">
		<ng-content></ng-content>
	</div>
</div>
