import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'bp-unread-email-notification-scene',
	templateUrl: './unread-email-notification-scene.component.html',
	styleUrls: [ './unread-email-notification-scene.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnreadEmailNotificationSceneComponent {
}
