/* eslint-disable @typescript-eslint/promise-function-async */
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { TelemetryService } from '@bp/shared/services';

import { NoTouchWizardRoutePathSegments } from '@bp/no-touch-wizard/shared/models';

import { HomePageComponent } from './pages';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: HomePageComponent,
	},

	{
		path: NoTouchWizardRoutePathSegments.Lead,
		loadChildren: () => import('@bp/no-touch-wizard/sections/lead')
			.then(m => m.NoTouchWizardLeadModule),
	},

	{
		path: NoTouchWizardRoutePathSegments.Wizard,
		loadChildren: () => import('@bp/no-touch-wizard/sections/wizard')
			.then(m => m.NoTouchWizardSectionsWizardModule),
	},

	{
		path: NoTouchWizardRoutePathSegments.SessionExpired,
		loadChildren: () => import('@bp/no-touch-wizard/sections/session-expired')
			.then(m => m.SessionExpiredModule),
	},
];

@NgModule({
	imports: [
		QuicklinkModule,
		RouterModule.forRoot(routes, {
			enableTracing: false,
			initialNavigation: 'enabledNonBlocking',
			paramsInheritanceStrategy: 'always',
			onSameUrlNavigation: 'reload',
			canceledNavigationResolution: 'replace',
			preloadingStrategy: QuicklinkStrategy,
			errorHandler: TelemetryService.routerErrorHandler,
		}),
	],
	exports: [ RouterModule, QuicklinkModule ],
})
export class AppRoutingModule { }
