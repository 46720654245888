import moment from 'moment';

import { DTO, MapFromDTO, MetadataEntity } from '@bp/shared/models/metadata';

export class WizardStep extends MetadataEntity {

	@MapFromDTO()
	type!: string;

	@MapFromDTO()
	timestamp?: number;

	@MapFromDTO()
	payload?: string;

	constructor(dto?: DTO<WizardStep>) {
		super(dto);

		this.timestamp = this.timestamp ?? moment().unix();
	}

}
