import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DTO } from '@bp/shared/models/metadata';
import { bypassAuthorizationHeaderCheck } from '@bp/shared/services';

import { ICreateSessionApiRequest, WizardSession, WizardStep } from '../models';

export const SESSION_API_PATH = 'session';

@Injectable({
	providedIn: 'root',
})
export class WizardSessionApiService {

	private readonly _basePath = `${ SESSION_API_PATH }`;

	constructor(private readonly _http: HttpClient) { }

	private readonly _factory = (dto: DTO<WizardSession>): WizardSession => new WizardSession(dto);

	create(request: ICreateSessionApiRequest): Observable<WizardSession> {
		return this._http
			.post<DTO<WizardSession>>(
			this._basePath,
			request,
			{ context: bypassAuthorizationHeaderCheck() },
		)
			.pipe(map(this._factory));
	}

	load(): Observable<WizardSession> {
		return this._http
			.get<DTO<WizardSession>>(this._basePath)
			.pipe(map(this._factory));
	}

	saveStep(step: WizardStep): Observable<void> {
		return this._http.patch<void>(this._basePath, <Partial<WizardSession>>{
			history: [ step ],
		});
	}

	sendContinueWizardEmail(email: string): Observable<void> {
		return this._http.post<void>(`${ this._basePath }/continue-workflow`, { email });
	}

}
