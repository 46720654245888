import { DTO, MapFromDTO, MetadataEntity } from '@bp/shared/models/metadata';

export class WizardCrmMerchant extends MetadataEntity {

	@MapFromDTO()
	owner!: {
		id: string;
		email: string;
		firstName: string;
		lastName: string;
	};

	@MapFromDTO()
	companyName!: string;

	@MapFromDTO()
	companyWebsite!: string;

	@MapFromDTO()
	crmMerchantId!: string;

	@MapFromDTO()
	paymentMethodType!: number;

	readonly isCreditCardPaymentMethod: boolean;

	constructor(dto?: DTO<WizardCrmMerchant>) {
		super(dto);

		this.isCreditCardPaymentMethod = this.paymentMethodType === 0;
	}

}
