import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@bp/shared/environments';
import '@bp/shared/startup';
import { resetCompiledComponentsOnHMR } from '@bp/shared/utilities';

import { AppModule } from './app';

if (environment.isRemoteServer)
	enableProdMode();
else
	resetCompiledComponentsOnHMR();

void platformBrowserDynamic()
	.bootstrapModule(AppModule, {
		ngZoneEventCoalescing: false,
		ngZoneRunCoalescing: false,
	});
