import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';

import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesLottiePlayerModule } from '@bp/shared/features/lottie-player';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedFeaturesMediaModule } from '@bp/shared/features/media';

import {
	UnreadEmailNotificationSceneComponent, ContinueWorkflowMockLinkComponent, InformationPageShellComponent, BackButtonComponent
} from './components';

const EXPOSED = [
	BackButtonComponent,
	UnreadEmailNotificationSceneComponent,
	ContinueWorkflowMockLinkComponent,
	InformationPageShellComponent,
];

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		MatCardModule,
		MatRippleModule,

		SharedFeaturesSvgIconsModule,
		SharedDirectivesModule,
		SharedFeaturesLottiePlayerModule,
		SharedComponentsCoreModule,
		SharedFeaturesMediaModule,

		RouterModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class NoTouchWizardSharedComponentsModule {
}
