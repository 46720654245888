import { Component, ChangeDetectionStrategy } from '@angular/core';

import { EnvironmentService, MockedBackendState } from '@bp/shared/services';
import { JwtToken } from '@bp/shared/utilities';

import { getNeverExpiringEpoch } from '@bp/shared-domains-identity';

import { WizardSessionJwtPayloadDTO, WizardConfigIds, WizardFeature } from '@bp/no-touch-wizard/domains/session';

@Component({
	selector: 'bp-footer',
	templateUrl: './footer.component.html',
	styleUrls: [ './footer.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

	mockedBackendIsActive = MockedBackendState.isActive;

	devLinks = [
		{
			title: '1.1 Start Wizard',
			url: '/',
		},
		{
			title: '1.2 Continue Wizard',
			url: `/wizard/continue?jwt=${ this._generateMockAuthJWT(WizardConfigIds.Default, [
				WizardFeature.getSession.claim,
			]) }`,
		},
		{
			title: '2.1 Start Enterprise Wizard',
			url: `/?id=${ WizardConfigIds.EnterpriseMerchant }`,
		},
		{
			title: '2.1 Start Enterprise OAuth Existing Email  Wizard',
			url: `/?id=${ WizardConfigIds.EnterpriseOAuthExistingEmail }`,
		},
		{
			title: '2.2 Continue Enterprise Wizard',
			url: `/wizard/continue?jwt=${ this._generateMockAuthJWT(WizardConfigIds.EnterpriseMerchant, [
				WizardFeature.getSession.claim,
			]) }`,
		},
		{
			title: '3.1 Start Only Woocommerce Wizard',
			url: `/?id=${ WizardConfigIds.Woocommerce }`,
		},
		{
			title: '3.2 Continue Only Woocommerce Wizard',
			url: `/wizard/continue?jwt=${ this._generateMockAuthJWT(WizardConfigIds.Woocommerce, [
				WizardFeature.getSession.claim,
			]) }`,
		},
		{
			title: '4.1 Start OAuth Existing Email Wizard',
			url: `/?id=${ WizardConfigIds.OAuthExistingEmail }`,
		},
		{
			title: '4.2 Start OAuth Continue Workflow Email Wizard',
			url: `/?id=${ WizardConfigIds.OAuthContinueWorkflow }`,
		},
	];

	constructor(public environment: EnvironmentService) {}

	private _generateMockAuthJWT(wizardSessionId: string, permissions: string[]): string {
		return JwtToken.encode<WizardSessionJwtPayloadDTO>({
			wizardSessionId,
			wizardSetupId: wizardSessionId,
			userEmail: 'mock-auth-test@bridgerpay.com',
			permissions,
			exp: getNeverExpiringEpoch(),
		});
	}
}
