export enum NoTouchWizardRoutePathSegments {
	// #region SECTION Lead

	Lead = 'lead',

	EmailVerificationEmailSent = 'email-verification-email-sent',

	ContinueWizardEmailSent = 'continue-wizard-email-sent',

	// #endregion !SECTION

	// #region SECTION Wizard

	Wizard = 'wizard',

	Integrations = 'integrations',

	Continue = 'continue',

	Welcome = 'welcome',

	// #endregion !SECTION

	SessionExpired = 'session-expired',
}
