import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiMockPluginsManagerService } from '@bp/shared/services';

import { WizardSessionEffects } from './state/wizard-session.effects';
import { FEATURE_STATE_KEY, reducer } from './state/wizard-session.reducer';
import { wizardSessionStateRehydratorMetaReducer } from './state/wizard-session-state-rehydrator.meta-reducer';

@NgModule({
	imports: [
		StoreModule.forFeature(
			FEATURE_STATE_KEY,
			reducer,
			{
				metaReducers: [ wizardSessionStateRehydratorMetaReducer ],
			},
		),
		EffectsModule.forFeature([ WizardSessionEffects ]),
	],
})
export class NoTouchWizardDomainsSessionRootModule {

	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(
			async () => import('./api-mock-plugin/session-api-mock-plugin'),
		);
	}

}
